<script>
import Layout from "../../layouts/main.vue";

export default {
  name: "custom-maintenance-2",
  data() {
    return {
      title: "index",
      items: [
        {
          text: "newpage",
          href: "/",
        },
        {
          text: "index",
          active: true,
        },
      ],
    };
  },
  components: {
    Layout
  },
};
</script>

<template>
  <Layout>
    <div class="auth-page-wrapper pt-5">

      <div class="auth-one-bg-position" id="auth-particles">
        <iframe src="https://maqro.itechcrew.mx/"
                width="100%"
                height="800"
                frameborder="0"
                style="position:relative;
                z-index:999;display: flex;
                flex-direction: row;
                justify-content: flex-start;
                margin-left: 150px;
                align-items: center;top:0"
                ref="frame">
        </iframe>
      </div>
    </div>
  </Layout>
</template>
